import { createSlice } from '@reduxjs/toolkit';
import { Profile } from '../../../user/profile/profileUtils';
import { getProfileDetails } from './getProfileDetails';

export type ProfileDetails = {
  profile: Profile | undefined;
  status: string;
  sidebarCollapsed: boolean;
};
const initialState: ProfileDetails = {
  profile: undefined,
  status: 'idle',
  sidebarCollapsed: false
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    toggleSidebarCollapsed(state: any) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileDetails.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(getProfileDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfileDetails.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.status = 'loaded';
      });
  }
});

export const { toggleSidebarCollapsed } = profileSlice.actions;
export default profileSlice.reducer;

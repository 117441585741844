import { configureStore } from '@reduxjs/toolkit';
import { getAuthLevel, getRememberMe, handleRememberMeRefresh } from '../services/auth';
import { AUTH_LEVEL } from '../services/authTypes';
import reducers from './reducers';
import { INITIAL_AUTH_STATE } from './reducers/userAuthReducer';

let signedIn = false;
let authLevel = AUTH_LEVEL.NONE;

let rememberMe = false;

const authState = getAuthLevel();
if (authState && authState !== 'null') {
  signedIn = true;
  authLevel = authState;
}

const rememberMeCookie = getRememberMe();
if (rememberMeCookie) {
  rememberMe = rememberMeCookie;
}

const persistedState = {
  auth: {
    ...INITIAL_AUTH_STATE,
    isSignedIn: signedIn,
    authLevel,
    rememberMe: rememberMe ?? false,
    showModal: typeof sessionStorage != 'undefined' ? sessionStorage.getItem('showModal') : false
  }
};

const store = configureStore({
  reducer: reducers,
  preloadedState: persistedState
});
if (rememberMe) {
  handleRememberMeRefresh(store);
}

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { GET_404_RECOMMENDATIONS } from "../actions/types";

const INITIAL_STATE = {
  recommendations: {
    "contentType":"",
    "items":[]
  }
};

const dynamic404RecommendationReducer = (state = INITIAL_STATE, action) => {
  if (action.type === GET_404_RECOMMENDATIONS) {
    return { recommendations: action.payload.recommendations };
  }
  return state;
};

export default dynamic404RecommendationReducer;
import { SIGN_OUT } from './types';
import { handleSignOut } from '../../services/auth';

const signOutAction = () => async (dispatch, getState) => {
  const state = getState();
  if (state.auth.isSignedIn === false) {
    return;
  }
  const success = await handleSignOut();
  if (success) {
    sessionStorage.clear();
    return dispatch({
      type: SIGN_OUT,
      payload: { isSignedIn: false }
    });
  } else {
    return dispatch({
      type: SIGN_OUT,
      payload: { isSignedIn: true }
    });
  }
};

export default signOutAction;

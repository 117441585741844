export const SIGN_IN = 'SIGN_IN';
export const LINK_SIGN_IN = 'LINK_SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const REGISTER = 'REGISTER';
export const PROFILE_COMPLETE = 'PROFILE_COMPLETE';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_RETRIEVED = 'USER_RETRIEVED';
export const USER_DELETED = 'USER_DELETED';
export const SEARCH = 'SEARCH';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const USER_FEED = 'USER_FEED';
export const LAST_ACTION = 'LAST_ACTION';
export const GET_404_RECOMMENDATIONS = 'GET_404_RECOMMENDATIONS';
export const REMEMBER_ME = 'REMEMBER_ME';
export const GET_USER_MATCHES = 'GET_USER_MATCHES';
export const USER_MATCH_STATUS = 'USER_MATCH_STATUS';
export const SELECT_USER_MATCH = 'SELECT_USER_MATCH';
export const TOGGLE_ALL_USER_MATCHES = 'TOGGLE_ALL_USER_MATCHES';
export const TOGGLE_USER_MATCH_INBOX = 'TOGGLE_USER_MATCH_INBOX';
export const TOGGLE_EDIT_USER_MATCH_INBOX = 'TOGGLE_EDIT_USER_MATCH_INBOX';
export const UPDATE_USER_MATCHES = 'UPDATE_USER_MATCHES';
export const TOGGLE_GLOBAL_MODAL = 'TOGGLE_GLOBAL_MODAL';
export const GLOBAL_MODAL_CLOSE_STATUS = 'GLOBAL_MODAL_CLOSE_STATUS';
export const TOGGLE_ATTRIBUTION_MODAL = 'TOGGLE_ATTRIBUTION_MODAL';
export const GLOBAL_MODAL_CONFIGURATION = 'GLOBAL_MODAL_CONFIGURATION';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const TEMP_USER_PROFILE_ANSWER = 'TEMP_USER_PROFILE_ANSWER';
export const PROFILE_COMPLETION_STATUS = 'PROFILE_COMPLETION_STATUS';
export const UPDATE_USER_PROFILE_LOADING = 'UPDATE_USER_PROFILE_LOADING';
export const TOGGLE_USER_MESSAGES = 'TOGGLE_USER_MESSAGES';
export const TOGGLE_APPLY_MODAL = 'TOGGLE_APPLY_MODAL';
export const AUTHENTICATION = 'authentication';

let bc = null;

const getBroadcastChannel = (name) => {
  if (bc && bc.name === name) {
    return bc;
  } else {
    bc = new BroadcastChannel(name);
  }
  return bc;
};

export const broadcast = (name, data) => {
  try {
    getBroadcastChannel(name).postMessage(data);
  } catch (error) {
    console.error('unable to Broadcast', name);
  }
};

export const broadcastHandler = (name, callback) => {
  try {
    getBroadcastChannel(name).onmessage = function (ev) {
      callback(ev);
    };
    getBroadcastChannel(name).onmessageerror = function () {
      console.error('error using BroadcastHandler', name);
    };
  } catch (error) {
    console.error('unable to use BroadcastHandler', name);
  }
};

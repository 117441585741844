import { getAuthLevel } from '../../services/auth';
import { AUTH_LEVEL } from '../../services/authTypes';
import {
  LINK_SIGN_IN,
  PROFILE_COMPLETE,
  REGISTER,
  REMEMBER_ME,
  SIGN_IN,
  USER_RETRIEVED,
  USER_UPDATED
} from '../actions/types';

export const INITIAL_AUTH_STATE = {
  isSignedIn: false,
  authLevel: AUTH_LEVEL.NONE,
  user: {},
  completeProfile: false,
  rememberMe: false,
  errors: {
    general: '',
    email: '',
    password: ''
  }
};

const userAuthReducer = (state = INITIAL_AUTH_STATE, { type, payload }) => {
  switch (type) {
    case SIGN_IN:
      if (payload.isSignedIn) {
        return {
          isSignedIn: payload.isSignedIn,
          user: payload.user,
          authLevel: AUTH_LEVEL.FULL,
          errors: INITIAL_AUTH_STATE.errors,
          rememberMe: payload.rememberMe
        };
      } else {
        return {
          ...state,
          errors: payload.errors
        };
      }
    case REMEMBER_ME:
      return {
        ...state,
        isSignedIn: true,
        authLevel: getAuthLevel() ?? AUTH_LEVEL.PARTIAL,
        user: {}
      };
    case LINK_SIGN_IN:
      return {
        ...state,
        isSignedIn: payload.isSignedIn,
        authLevel: getAuthLevel() ?? AUTH_LEVEL.PARTIAL,
        user: payload.user
      };
    case USER_UPDATED:
      return {
        ...state,
        user: payload.user
      };
    case USER_RETRIEVED:
      return {
        ...state,
        user: payload.user
      };
    case PROFILE_COMPLETE:
      return {
        ...state,
        completeProfile: false
      };
    case REGISTER:
      return {
        isSignedIn: payload.isSignedIn,
        rememberMe: payload.rememberMe,
        completeProfile: payload.completeProfile,
        authLevel: AUTH_LEVEL.FULL,
        errors: payload.error,
        user: payload.user
      };
    default:
      return state;
  }
};

export default userAuthReducer;

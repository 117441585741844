/**
 * @Doc Fontawesome + React documentation: https://fontawesome.com/how-to-use/on-the-web/using-with/react
 *
 * We are using a library to add our SVG icons to, this is to reduce down the
 * number of icons we preload, if you need to use any fontawesome icon in the
 * application, please make sure its added to the below scripts.
 *
 */

/**
 * Import fontawesome library map, used to store all the icons we need to use
 * in the application.
 */
import { library } from '@fortawesome/fontawesome-svg-core';

/**
 * Import fontawesome REGULAR icons
 */
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUp,
  faBars,
  faBuilding,
  faBusinessTime,
  faCalendarDay,
  faCalendarRange,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClipboardListCheck,
  faCupTogo,
  faDiploma,
  faDownload,
  faEllipsisVertical,
  faExclamationCircle,
  faExternalLink,
  faFileInvoice,
  faFire,
  faFlag,
  faGlobe,
  faGrid2Plus,
  faInfoCircle,
  faLightbulb,
  faLightbulbOn,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPlus,
  faPresentation,
  faPuzzlePiece,
  faSearch,
  faShareAlt,
  faStar,
  faTrash,
  faTrophy,
  faTypewriter,
  faUserCircle,
  faUserGraduate,
  faVenus,
  faWandMagicSparkles,
  faBell as farBell,
  faBookmark as farFaBookmark,
  faNewspaper as farFaNewspaper,
  faPeopleGroup as farFaPeopleGroup
} from '@fortawesome/pro-regular-svg-icons';

/**
 * Import fontawesome SOLID icons
 */
import {
  faAddressBook,
  faAward,
  faBasketballBall,
  faBell,
  faBezierCurve,
  faBinoculars,
  faBookOpen,
  faBriefcase,
  faCalendar as faCalendarSolid,
  faCalendarXmark,
  faCircleInfo,
  faEnvelope,
  faFlag as faFlagSolid,
  faGear,
  faGraduationCap,
  faHeart,
  faHome,
  faHorizontalRule,
  faHourglassHalf,
  faInbox,
  faLink,
  faLocationDot as faLocationDotSolid,
  faMedal,
  faMemoCircleCheck,
  faMessage,
  faMessageLines,
  faNewspaper,
  faPeopleGroup,
  faRandom,
  faRocket,
  faShareNodes,
  faStar as faStarSolid,
  faSuitcase,
  faThumbsDown,
  faThumbsUp,
  faTree,
  faUniversity,
  faUser,
  faCheckCircle as fasCheckCircle,
  faBookmark as fasFaBookmark,
  faPenToSquare as fasPenToSquare
} from '@fortawesome/pro-solid-svg-icons';

/** |
 * Import fontawesome LIGHT icons
 */
import { faCalendar, faClock, faLocationDot, faMinus, faTimes } from '@fortawesome/pro-light-svg-icons';

/**
 * Import fontawesome DUOTONE icons
 */
// import { } from '@fortawesome/pro-duotone-svg-icons'

/**
 * Import fontawesome BRANDS icons
 */
import {
  faFacebookF,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faWhatsapp,
  faXTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

/**
 * Add the icons to the relevant collections: only seperated into multiple
 * collections, so easier to manage.
 **/
const faRegularCollection = [
  farBell,
  faSearch,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faBars,
  faUserCircle,
  faArrowLeft,
  faBasketballBall,
  farFaBookmark,
  faRandom,
  faBezierCurve,
  faUniversity,
  faGraduationCap,
  faExclamationCircle,
  faCheckCircle,
  faExternalLink,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faPen,
  faShareAlt,
  faPlus,
  faGrid2Plus,
  faArrowRightFromBracket,
  faDownload,
  faPuzzlePiece,
  faLightbulbOn,
  faTrophy,
  faTypewriter,
  faLightbulb,
  faCupTogo,
  faPaperPlane,
  faClipboardListCheck,
  faFileInvoice,
  faFire,
  faWandMagicSparkles,
  farFaPeopleGroup,
  faUserGraduate,
  faVenus,
  farFaNewspaper,
  faTrash,
  faInfoCircle,
  farFaNewspaper,
  faPenToSquare,
  faCalendarDay,
  faPresentation,
  faDiploma,
  faCalendarRange,
  faBusinessTime,
  faCheck,
  faStar,
  faEllipsisVertical,
  faFlag,
  faBuilding
];
const faSolidCollection = [
  faUser,
  faHorizontalRule,
  fasFaBookmark,
  faEnvelope,
  faLink,
  faCalendarXmark,
  faBell,
  faMemoCircleCheck,
  faBookOpen,
  faMessageLines,
  faAward,
  faHeart,
  faAddressBook,
  fasCheckCircle,
  faAward,
  faHeart,
  faNewspaper,
  faMedal,
  faPeopleGroup,
  faRocket,
  faTree,
  faCalendarSolid,
  faLocationDotSolid,
  faGear,
  faCircleInfo,
  faShareNodes,
  faHome,
  faBriefcase,
  faInbox,
  faBinoculars,
  faMessage,
  faSuitcase,
  fasPenToSquare,
  faThumbsUp,
  faThumbsDown,
  faHourglassHalf,
  faStarSolid,
  faFlagSolid
];
const faLightCollection = [faTimes, faSearch, faCheck, faMinus, faClock, faLocationDot, faCalendar];
const faDuotoneCollection = [];
const faBrandCollection = [
  faFacebookF,
  faInstagram,
  faTiktok,
  faLinkedinIn,
  faXTwitter,
  faYoutube,
  faGoogle,
  faWhatsapp,
  faGlobe
];

library.add(faRegularCollection, faSolidCollection, faLightCollection, faDuotoneCollection, faBrandCollection);

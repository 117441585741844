import { USER_FEED } from "../actions/types";

const INITIAL_STATE = {
  userFeed: [],
  recentlyViewed: []
};

const feedReducer = (state = INITIAL_STATE, action) => {
  if (action.type === USER_FEED) {
    return { userFeed: action.payload.userFeed, recentlyViewed: action.payload.recentlyViewed };
  }
  return state;
};

export default feedReducer;

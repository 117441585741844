import { combineReducers } from 'redux';
import bookmarkReducer from '../features/bookmark/bookmarkSlice';
import profileReducer from '../features/profile/profileSlice';
import dynamic404RecommendationReducer from './dynamic404RecommendationReducer';
import feedReducer from './feedReducer';
import globalModalReducer from './globalModalReducer';
import lastActionReducer from './lastActionReducer';
import profileCompletionReducer from './profileCompletionReducer';
import searchReducer from './searchReducer';
import userAuthReducer from './userAuthReducer';
import userMatchesReducer from './userMatchesReducer';

const appReducer = combineReducers({
  auth: userAuthReducer,
  feed: feedReducer,
  bookmarkInfo: bookmarkReducer,
  profile: profileReducer,
  globalModal: globalModalReducer,
  matches: userMatchesReducer,
  searchData: searchReducer,
  lastAction: lastActionReducer,
  profileCompletion: profileCompletionReducer,
  dynamic404recommendations: dynamic404RecommendationReducer,
});

export default (state, action) => {
  if (action.type === 'SIGN_OUT') {
    return appReducer({ searchData: state.searchData }, action);
  }

  return appReducer(state, action);
};

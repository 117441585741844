import { LAST_ACTION } from '../actions/types';

export const INITIAL_ACTION_STATE = {
  path: '',
  action: ''
};

const lastActionReducer = (state = INITIAL_ACTION_STATE, { type, payload }) => {
  if (type == LAST_ACTION) {
    return payload;
  }
  if (typeof sessionStorage != 'undefined') {
    let lastAction = sessionStorage.getItem('lastAction');
    if (lastAction) {
      lastAction = JSON.parse(lastAction);
      return lastAction;
    }
  }

  return state;
};

export default lastActionReducer;

/**
 * Chilli UI styling
 */
import './src/assets/styles/global.scss';

/**
 * Adding fontawesome library to be loaded on all pages too, saves adding the
 * same import script to every page.
 */
import './src/assets/js/fontawesome';

/**
 * Google fonts
 * Installed locally to speed up serving
 */
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

/**
 * Adding web components from @group-gti/chilli-design-system
 * so they are available for SSR.
 */
import { defineCustomElements } from '@group-gti/chilli-design-system/loader';

defineCustomElements();

// Additional polyfils
import './src/app/broadcastChannels/polyfil';

// Redux wrapper component.
import wrapWithProvider from './src/app/redux/wrapWithProvider';

// Used to configure the redux store and add root toaster element
export const wrapRootElement = wrapWithProvider;

//  utilities
import browserHydrateFunction from './src/app/utils/gatsby/browser-hydrate-function';

export const replaceHydrateFunction = browserHydrateFunction;

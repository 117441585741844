import { createSlice } from '@reduxjs/toolkit';
import { Article, Course, GraduateProfile, Opportunity, Organisation } from '../../../../types/content';
import { addBookmark } from './addBookmark';
import { getBookmarks } from './getBookmarks';
import { removeBookmark } from './removeBookmark';

export type Bookmark = {
  state: any;
  bundle: string;
  bookmarked: number;
  entityId: string;
  entityType: string;
  entityUuid: string;
  content: Article | Organisation | Opportunity | Event | Course | GraduateProfile;
};

export type BookmarkInfo = {
  bookmarks: { [entityUuid: string]: Bookmark };
  status: string;
};

const initialState: BookmarkInfo = { status: 'idle', bookmarks: {} };

const bookmarkSlice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    // omit reducer cases
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarks.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(getBookmarks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.bookmarks = action.payload;

        state.status = 'loaded';
      });
    builder
      .addCase(removeBookmark.rejected, (state) => {
        state.status = 'loaded';
      })
      .addCase(removeBookmark.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeBookmark.fulfilled, (state, action) => {
        delete state.bookmarks[action.payload];
        state.status = 'loaded';
      });
    builder
      .addCase(addBookmark.rejected, (state) => {
        state.status = 'loaded';
      })
      .addCase(addBookmark.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addBookmark.fulfilled, (state, action) => {
        state.bookmarks[action.payload.entityUuid] = action.payload;
        state.status = 'loaded';
      });
  }
});

export default bookmarkSlice.reducer;

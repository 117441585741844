import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { SIGN_OUT } from '../redux/actions/types';
import store from '../redux/configureStore';
import { getAuthCSRFToken, refreshToken, removeAuthCookies } from './auth';
import { AUTH_LEVEL } from './authTypes';

export type InternalAPIError = {
  success: boolean;
  error: any;
  status: number;
};

/**
 * Intercept all requests sent via this instance
 * and add in the auth token and default headers.
 *
 * @param {boolean} requireToken Whether or not a token is required to make this request.
 *
 * @return {AxiosInstance} Returns Axios client.
 */
export default (requireToken: boolean = true) => {
  const client = axios.create({
    baseURL: `/`
  });
  if (requireToken) {
    client.interceptors.request.use(
      async (config) => {
        const { success, token } = await refreshToken();
        if (success) {
          if (isEmpty(config.headers)) {
            config.headers = {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              'X-Csrf-Token': await getAuthCSRFToken()
            };
          } else {
            config.headers = {
              ...config.headers,
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              'X-Csrf-Token': await getAuthCSRFToken()
            };
          }
          return config;
        } else {
          if (store.getState().auth.authLevel != AUTH_LEVEL.NONE) {
            console.debug('Refresh Failed. Remove auth info');
            removeAuthCookies();
            store.dispatch({ type: SIGN_OUT });
          }
          return Promise.reject(new Error('Token not retrievable'));
        }
      },
      (error) => {
        console.debug('Refresh Failed', error);
        if (store.getState().auth.authLevel != AUTH_LEVEL.NONE) {
          console.debug('Refresh Failed. Remove auth info');
          removeAuthCookies();
          store.dispatch({ type: SIGN_OUT });
        }
        return Promise.reject(new Error('Token not retrievable'));
      }
    );
  }
  return client;
};

/**
 * Handles errors when the internalAPI interceptor is used.
 *
 * @param {any} error
 * @return {InternalAPIError}
 */
export const handleInternalAPIError = (error: any) => {
  // request was made but did not pass validateStatus (200-300)
  if (error.response) {
    return { success: false, status: error.response.status, data: {} };
  }
  // Internal API failed to refresh token
  if (error.status && error.status == 401) {
    return { success: false, status: error.status, data: {} };
  }
  // unhandled error
  return { success: false, status: 0, data: {} };
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import internalAPI from '../../../services/internalAPI';

export const removeBookmark = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    rejectValue: { error: string };
  }
>('bookmark/remove', async (entityUuid: string, { rejectWithValue }) => {
  try {
    const response = await internalAPI(true).delete(`/api/bookmark/node/${entityUuid}`);
    if (response.status == 204) {
      return entityUuid;
    } else {
      console.error(`Unable to remove bookmark. Status: ${response.status}`);
      return rejectWithValue({ error: 'Unable to remove bookmark' });
    }
  } catch (e) {
    console.error(`Unable to remove bookmark`, e);
    return rejectWithValue({ error: 'Unable to remove bookmark' });
  }
});

import { SEARCH, SEARCH_LOADING } from '../actions/types';

const INITIAL_STATE = {
  search: { },
  resultCount: 0,
  results: [],
  promotedResults: [],
  loading: true
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH:
      if (action.payload.search.loadMore) {
        const results = state.results.concat(action.payload.results);
        return {
          search: { ...action.payload.search },
          resultCount: action.payload.resultCount,
          results: results,
          promotedResults: action.payload.promotedResults,
          loading: false,
          loadMore: action.payload.search.loadMore,
          facets: action.payload.facets
        };
      } else {
        return {
          search: { ...action.payload.search },
          resultCount: action.payload.resultCount,
          results: action.payload.results,
          promotedResults: action.payload.promotedResults,
          loading: false,
          loadMore: action.payload.search.loadMore,
          facets: action.payload.facets
        };
      }

    case SEARCH_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
        loadMore: action.payload.search.loadMore,
        search: { ...action.payload.search }
      };
    default:
      return state;
  }
};

export default searchReducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import internalAPI from '../../../services/internalAPI';
import { Profile } from '../../../user/profile/profileUtils';
import { RootState } from '../../configureStore';

export const getProfileDetails = createAsyncThunk<
  // Return type of the payload creator
  Profile,
  // First argument to the payload creator
  undefined,
  { state: RootState }
>(
  'profile/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await internalAPI(false)
        .post('/profile', {}, {})
        .catch((e) => {
          return false;
        });
      if (response) {
        return response.data;
      } else {
        return rejectWithValue({ error: 'Unable to retrieve profile' });
      }
    } catch (e) {
      return rejectWithValue({ error: 'Unable to retrieve profile' });
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status !== 'idle') {
        return false;
      }
    }
  }
);

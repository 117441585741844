module.exports = [{
      plugin: require('../plugins/gatsby-source-content-recommendations/gatsby-browser.js'),
      options: {"plugins":[],"host":"gradireland.com","recommenderHost":"http://media-recommender-2-24.ha.shared.groupgti.net","itemLimit":"5","batchSize":50,"TTLForRecommendationsInS":86400,"HourOfDayToFetchBy":5},
    },{
      plugin: require('../plugins/gatsby-plugin-google-tagmanager-cookiefirst/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TZP8FP7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"chilli-ui","site":"gradireland"}},"gtmAuth":"hagXZX5dMgIi8v3a8IZbbw","gtmPreview":"env-1","routeChangeEventName":"chilli-ui-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/gatsby-plugin-chilli-ui/gatsby-browser.js'),
      options: {"plugins":[],"api":"/ext/svc/abacus-capture/a3cdeb4f-336e-42bb-b197-970af0eee26b","defaultDataLayer":{"type":"object","value":{"platform":"chilli-ui","site":"gradireland"}},"abacusEnabled":true},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

export enum AUTH_LEVEL {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE'
}
export enum AUTH_TYPES {
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN'
}
export enum AUTH_STATUS {
  BRUTE_ERROR = -1,
  GENERAL_ERROR = 0,
  SUCCESS = 1
}
export type TokenRefreshResponse = {
  success: boolean;
  token: string;
  status: number;
};
export const COOKIE_TOKEN = 'token';
export const COOKIE_AUTH_LEVEL = 'authLevel';
export const COOKIE_REMEMBER_ME = 'rememberMe';
export const CSRF_TOKEN = 'CSRFtoken';
export type Credentials = {
  email: string;
  password: string;
  rememberMe: boolean;
};
export const GENERAL_AUTH_ERROR_MESSAGE = 'Oh no, incorrect username or password';
export const BRUTE_AUTH_ERROR_MESSAGE = 'Sorry, too many login attempts. Request a new password or try again later';
export type RegisterResponse = {
  success: boolean;
  error: string;
  user: any;
};
export enum ActionType {
  VIEW = 'VIEW',
  APPLY = 'APPLY',
  APPLY_EMAIL = 'APPLY_EMAIL',
  BOOKMARK = 'BOOKMARK',
  PROFILE_COMPLETION_EMAIL = 'PROFILE_COMPLETION_EMAIL'
}
export type LastActionData = {
  lastPage: String;
  action: ActionType;
  actionData: any;
};
// hard-coded list of ref data uuids, multiple lists for each registration origin
export const AuthFlow = {
  // first standard is specifically not social auth
  Standard: {
    Standard: '6d9c2045-1a13-4b1c-2ea6-8169171e0259', // second standard is regular through /reg flow, same for social
    ApplyUrl: 'b2172961-e605-4f61-8462-d271950c3316',
    ApplyEmail: '826793bc-ff1c-4bda-a7d8-278db8beb11f',
    Pathway: '7fc4ca42-5a83-4867-3f3a-9610f6b5ca64',
    EventPreregister: '1c16f0fb-9f1b-4646-35cb-f80f9cd0872d',
    OpportunityPreregister: 'af230221-2f0e-4c88-2071-4b42cfc75cae',
    Bookmark: 'a2e89da7-2b38-4dda-d569-ad0dfed9c36d'
  },
  Social: {
    // login is clicking social auth through the login pages - not a social login
    Login: {
      Standard: '3efef188-326d-43e6-9a75-47c3db6cdcd0',
      ApplyUrl: 'b9f0bd10-305d-4d11-7a88-7476412b15d6',
      ApplyEmail: '8266c23a-0867-4021-7bcf-be71366a94c0',
      Pathway: '1351e65e-24f4-4e84-a69d-8f68cba96f21',
      EventPreregister: 'f777fa2e-c509-49b3-2ac6-af13c786119b',
      OpportunityPreregister: 'e870ff48-3372-4e1d-bc29-2e77d112006b',
      Bookmark: 'a84c051e-e321-4622-528d-36056ad01f03'
    },
    // register is clicking social auth through the register pages
    Register: {
      Standard: '05f2ad54-26db-4d1e-5f80-2ba658015f5a',
      ApplyUrl: '1e4a9cdf-61c4-47d1-0d13-a8455b0e15e2',
      ApplyEmail: '20320aa8-39fd-493c-f183-512aecce68fa',
      Pathway: '68b8f869-d09a-4fbf-1aac-6f9bab7a0f0a',
      EventPreregister: 'c2b60946-1c96-4957-fcb2-f7c9c63928cb',
      OpportunityPreregister: '233ec4e4-0c8f-42dc-3622-801f53545d73',
      Bookmark: '2731635e-b400-403b-bfbf-1173ec8ec357'
    }
  }
};

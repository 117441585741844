import {
  PROFILE_COMPLETION_STATUS,
  TEMP_USER_PROFILE_ANSWER,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_LOADING
} from '../actions/types';

import { AppDispatch } from '../configureStore';

type Question = {
  [key: string]: string | string[];
};
type ProfileCompletion = {
  questions: Question | {};
  tempQuestions: Question | {}; // has not been submitted yet
  loading: boolean;
  userprofileCompletionState: boolean;
  percentage: number | undefined;
};

const INITIAL_STATE: ProfileCompletion = {
  questions: {},
  tempQuestions: {},
  loading: false,
  userprofileCompletionState: false,
  percentage: undefined
};

const updateQuestions = (state: ProfileCompletion, groupKey: string, fieldValue: Question, isTemp: boolean) => {
  const targetState = isTemp ? 'tempQuestions' : 'questions';
  // Check if the key exists in the profile object
  if (state[targetState][groupKey]) {
    return {
      ...state,
      loading: false,
      [targetState]: {
        ...state[targetState],
        [groupKey]: {
          ...state[targetState][groupKey],
          ...fieldValue
        }
      }
    };
  } else {
    // If the key doesn't exist, create it with the data
    return {
      ...state,
      loading: false,
      [targetState]: {
        ...state[targetState],
        [groupKey]: fieldValue
      }
    };
  }
};

const profileCompletionReducer: any = (state = INITIAL_STATE, action: AppDispatch) => {
  switch (action.type) {
    case TEMP_USER_PROFILE_ANSWER:
      const tempGroupKey = Object.keys(action.payload.profile)[0];
      const tempFieldValue = { ...action.payload.profile[tempGroupKey] };

      return updateQuestions(state, tempGroupKey, tempFieldValue, true);
    case PROFILE_COMPLETION_STATUS:
      const status = action.payload;
      return status ? {
        ...state,
        userprofileCompletionState: status
      } : INITIAL_STATE;

    case UPDATE_USER_PROFILE_LOADING:
      return { ...state, loading: action.payload };
    case UPDATE_USER_PROFILE:
      const groupKey = Object.keys(action.payload.profile)[0];
      const fieldValue = { ...action.payload.profile[groupKey] };
      return updateQuestions(state, groupKey, fieldValue, false);
    default:
      return state;
  }
};

export default profileCompletionReducer;

import { Course, Event, Opportunity } from '../../../types/content';
import {
  GET_USER_MATCHES,
  SELECT_USER_MATCH,
  TOGGLE_ALL_USER_MATCHES,
  TOGGLE_EDIT_USER_MATCH_INBOX,
  TOGGLE_USER_MATCH_INBOX,
  TOGGLE_USER_MESSAGES,
  UPDATE_USER_MATCHES,
  USER_MATCH_STATUS
} from '../actions/types';
import { AppDispatch } from '../configureStore';

export type UserMatch = {
  userMatchId: string;
  contentId: string;
  message: string;
  contentType: string;
  createdAtDate: string;
  expiryDate: string | null;
  createdAt: string;
  seen: boolean;
  saved: boolean;
  selected?: boolean;
  content: Opportunity | Event | Course;
};

export enum UserMatchAction {
  Seen = 'SEEN',
  Saved = 'SAVED',
  Dismissed = 'DISMISSED'
}

export enum UserMatchCollectStatus {
  Pending = 'PENDING',
  Obtained = 'OBTAINED',
  Updated = 'UPDATED'
}

export type UserMatchesState = {
  status: UserMatchCollectStatus;
  matches: UserMatch[];
  showInbox: boolean;
  showMessages: boolean;
  showMatchSelection: boolean;
};

const INITIAL_STATE: UserMatchesState = {
  status: UserMatchCollectStatus.Pending,
  matches: [],
  showInbox: true,
  showMessages: false,
  showMatchSelection: false
};

const userMatchesReducer: any = (state = INITIAL_STATE, action: AppDispatch): UserMatchesState => {
  switch (action.type) {
    case TOGGLE_USER_MESSAGES:
      return {
        ...state,
        showMessages: action.payload.status
      };
    case TOGGLE_USER_MATCH_INBOX:
      return {
        ...state,
        showInbox: action.payload.status
      };
    case TOGGLE_EDIT_USER_MATCH_INBOX:
      return {
        ...state,
        showMatchSelection: action.payload.status
      };
    case GET_USER_MATCHES:
      const updatedMatches = action.payload.matches.map((match: UserMatch) => {
        if (match.content?.educationProvider) {
          match.content.organisation = match.content?.educationProvider;
          delete match.content?.educationProvider;
        }
        return match;
      });

      return {
        ...state,
        status: UserMatchCollectStatus.Obtained,
        matches: updatedMatches
      };
    case TOGGLE_ALL_USER_MATCHES:
      return {
        ...state,
        matches: state.matches.map((m) => ({ ...m, selected: action.payload.status }))
      };
    case USER_MATCH_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    case SELECT_USER_MATCH:
      const { matchId, status } = action.payload;
      const selectedMatchIndex = state.matches.findIndex(({ userMatchId }) => userMatchId === matchId);

      if (selectedMatchIndex === -1) {
        return state;
      }

      return {
        ...state,
        status: UserMatchCollectStatus.Updated,
        matches: state.matches.map((m, index) => (index === selectedMatchIndex ? { ...m, selected: status } : m))
      };
    case UPDATE_USER_MATCHES:
      const { match, action: actionType } = action.payload;
      const matchIndex = state.matches.findIndex(({ userMatchId }) => userMatchId === match.userMatchId);

      if (matchIndex === -1) {
        return state;
      }

      if (actionType === UserMatchAction.Dismissed) {
        const updatedMatches = state.matches.filter((m) => m.userMatchId !== match.userMatchId);

        return {
          ...state,
          status: UserMatchCollectStatus.Updated,
          matches: updatedMatches
        };
      } else {
        const updatedMatches = state.matches.map((m) => (m.userMatchId === match.userMatchId ? match : m));

        return {
          ...state,
          status: UserMatchCollectStatus.Updated,
          matches: updatedMatches
        };
      }
  }

  return state;
};

export default userMatchesReducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import internalAPI from '../../../services/internalAPI';
import signOutAction from '../../actions/signOutAction';
import { RootState } from '../../configureStore';
import { Bookmark } from './bookmarkSlice';

export const getBookmarks = createAsyncThunk<
  // Return type of the payload creator
  { [entityUuid: string]: Bookmark },
  // First argument to the payload creator
  undefined,
  { state: RootState }
>(
  'bookmark/get',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await internalAPI(true).get('/api/bookmark');
      if (response.status == 200 && response.data) {
        return response.data;
      } else {
        return rejectWithValue({ error: 'Unable to retrieve bookmarks' });
      }
    } catch (e) {
      const err = e as AxiosError;
      if (err.response) {
        if (err.response.status == 401) {
          dispatch(signOutAction());
        }
      }
      console.error('Unable to retrieve bookmarks', e);
      return rejectWithValue({ error: 'Unable to retrieve bookmarks' });
    }
  },
  {
    condition: (_, { getState }) => {
      const { bookmarkInfo } = getState();
      if (bookmarkInfo.status !== 'idle') {
        return false;
      }
    }
  }
);
